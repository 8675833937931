import React from "react"
import { FaLinkedinIn } from "react-icons/fa"
import {
  generateSocialIconStyles,
  generateSocialButtonStyles,
} from "./social-icon-styles"
export function LinkedInIcon(props) {
  const LinkedInBlue = "#1569BF"
  return (
    <a
      className="button px-5"
      href="https://www.linkedin.com/in/derekahill/"
      style={generateSocialButtonStyles(props)}
    >
      <span className="icon is-size-7">
        <FaLinkedinIn style={generateSocialIconStyles(props, LinkedInBlue)} />
      </span>
    </a>
  )
}
