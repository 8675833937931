import React from "react"
import { LinkedInIcon } from "./linkedin-icon"
import { TwitterIcon } from "./twitter-icon"

export function SocialIcons(props) {
  return (
    <div>
      <LinkedInIcon iconColor={props.iconColor} />
      &nbsp;&nbsp;
      <TwitterIcon iconColor={props.iconColor} />
    </div>
  )
}
