import React from "react"
import Signup from "../social/signup"
import { SocialIcons } from "../social/social-icons"

// @ts-ignore
import {opgElementSpacing} from "../../styles/opg-variables.module.scss"

export default function Footer() {
  return (
    <footer
      className="footer"
      style={{
        marginTop: opgElementSpacing,
      }}
    >
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Signup class="opg-dark" />
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <p>Copyright © {new Date().getFullYear()}</p>
          </div>
          <div className="level-item extra-margin-tablet-right">
            <div>
              <SocialIcons iconColor="white" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
