import React from "react"
import { FaTwitter } from "react-icons/fa"
import {
  generateSocialIconStyles,
  generateSocialButtonStyles,
} from "./social-icon-styles"

export function TwitterIcon(props) {
  const twitterBlue = "#2AA3EF"
  return (
    <a
      className="button px-5"
      href="https://twitter.com/one_page_guide"
      style={generateSocialButtonStyles(props)}
    >
      <span className="icon is-size-7">
        <FaTwitter style={generateSocialIconStyles(props, twitterBlue)} />
      </span>
    </a>
  )
}
