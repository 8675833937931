// @ts-ignore
import {opgColorIcon} from "../../styles/opg-variables.module.scss"

export function generateSocialIconStyles(props, platformColor) {
  const styles = { fontSize: "2em" }

  switch (props.iconColor) {
    case "white":
      Object.assign(styles, { color: "white" })
      break
    case "grey-white-background":
      Object.assign(styles, { color: opgColorIcon })
      break
    case "grey-dark-background":
      Object.assign(styles, { color: opgColorIcon })
      break
    case "platform":
      Object.assign(styles, { color: platformColor })
      break
    default:
      break
  }
  return styles
}

export function generateSocialButtonStyles(props) {
  const styles = { backgroundColor: "transparent" }

  switch (props.iconColor) {
    case "white":
      Object.assign(styles, { borderColor: "#505050" })
      break
    case "grey-white-background":
      break
    case "grey-dark-background":
      Object.assign(styles, { borderColor: "Silver" })
      break
    case "platform":
      break
    default:
      break
  }
  return styles
}
