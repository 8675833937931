import React from "react"
import { Link } from "gatsby"
import { LinkedInIcon } from "../social/linkedin-icon"
import { TwitterIcon } from "../social/twitter-icon"
import Container from "../layout/container"
import { StaticImage } from "gatsby-plugin-image"

export default function Nav(props) {
  const [isActive, setisActive] = React.useState(false)

  let iconColor = "grey-dark-background"
  if (props.backgroundStyle === "white") {
    iconColor = "grey-white-background"
  }

  return (
    <Container>
      <nav
        className="navbar pl-5"
        role="navigation"
        aria-label="main navigation"
        style={{
          marginBottom: "-8px",
          backgroundColor: "transparent",
        }}
      >
        <div className="navbar-brand extra-margin-tablet-left">
          <Link className="navbar-item" to="/">
            <StaticImage
              src="../../images/index/one_page_guide_logo.png"
              alt="One page guide logo"
              width={200}
              placeholder="tracedSVG"
            />
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <a
            onClick={() => {
              setisActive(!isActive)
            }}
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="opg-navbar"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="opg-navbar"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              Home
            </Link>
          </div>
          <div className="navbar-end">
            <div className="navbar-item px-1">
              <LinkedInIcon iconColor={iconColor} />
            </div>
            <div className="navbar-item px-1 extra-margin-tablet-right">
              <TwitterIcon iconColor={iconColor} />
            </div>
          </div>
        </div>
      </nav>
    </Container>
  )
}
