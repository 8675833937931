import React from "react"
import Nav from "../sections/nav"
import Footer from "../sections/footer"
import "../../styles/app.scss"

export default function Layout(props) {
  const styles = {}
  if (props.backgroundStyle === "white") {
    Object.assign(styles, { backgroundColor: "white" })
  }
  return (
    <div style={styles}>
      <Nav backgroundStyle={props.backgroundStyle} />
      <div>{props.children}</div>
      <Footer />
    </div>
  )
}
