import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { FaEnvelope } from "react-icons/fa"
import { ResponsiveContext } from "../../contexts/responsive-context"
import Cookies from "js-cookie"

// @ts-ignore
import {opgAzureBlue} from "../../styles/opg-variables.module.scss"

function recordSignupInGA() {
  // @ts-ignore
  if (typeof window.gtag !== `function`) {
    return false
  }

  // @ts-ignore
  window.gtag("event", "sign_up", { method: "Default" })
}

function submitHubspotForm(email) {
  const portalId = "8562684"
  const formGuid = "3e3ec27f-cbff-40fd-a3b4-a462c53c60a2"

  const xhr = new XMLHttpRequest()
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

  const data = {
    fields: [
      {
        name: "email",
        value: email,
      },
    ],
    context: {
      hutk: Cookies.get("hubspotutk"),
      pageUri: window.location.href,
      pageName: window.location.pathname,
    },
  }

  const finalData = JSON.stringify(data)

  xhr.open("POST", url)
  xhr.setRequestHeader("Content-Type", "application/json")
  xhr.send(finalData)
}

class Signup extends React.Component {
  constructor(props) {
    super()
    this.state = { email: "" }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ email: event.target.value })
  }

  handleSubmit = async event => {
    event.preventDefault()
    const email = this.state.email
    // @ts-ignore
    const response = await addToMailchimp(email)
    if (response.result === "success") {
      submitHubspotForm(email)
      recordSignupInGA()
    }
    this.setState({ ...response })
  }

  render() {
    let emailSignupCta = "Be notified of new 1-pagers"

    if (this.context.isMobile || this.props.shortCta) {
      emailSignupCta = "Subscribe"
      if (this.context.isSmallMobile) {
        emailSignupCta = "Go"
      }
    }

    return (
      <div>
        <div onSubmit={this.handleSubmit} className="field is-grouped">
          <p className="control has-icons-left">
            <input
              className={`input ${this.props.class}`}
              type="text"
              aria-label="Enter your email"
              placeholder="Enter your email"
              defaultValue={this.state.email}
              onChange={this.handleChange}
              style={{ border: "none", boxShadow: "none" }}
            />
            <span
              className="icon is-small is-left"
              style={{ color: opgAzureBlue }}
            >
              <FaEnvelope />
            </span>
          </p>
          <div className="control">
            <button
              className="button is-info"
              onClick={this.handleSubmit}
              onKeyDown={this.handleSubmit}
              style={{ backgroundColor: opgAzureBlue }}
            >
              {emailSignupCta}
            </button>
          </div>
        </div>
        {this.state.result && (
          <p
            className={`${this.props.class}`}
            dangerouslySetInnerHTML={{ __html: this.state.msg }}
          ></p>
        )}
      </div>
    )
  }
}

Signup.contextType = ResponsiveContext

export default Signup
